import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBriefcase, FaGraduationCap, FaChevronDown } from 'react-icons/fa';

interface TimelineItem {
  id: number;
  type: 'work' | 'education';
  date: string;
  title: string;
  subtitle: string;
  description: string;
}

const timelineData: TimelineItem[] = [
  {
    id: 1,
    type: 'work',
    date: 'Sep 2023 - Jun 2024',
    title: 'Co-Founder & Product Lead',
    subtitle: 'Finquire, Seattle, WA',
    description: 'Used AI to democratize finance. Built personalized financial news retrieval and translator, AI financial planning, and chat with your financial data via mobile app and plaid integration',
  },
  {
    id: 2,
    type: 'work',
    date: 'Jun 2023 - Sep 2023',
    title: 'Data Solutions Intern',
    subtitle: 'Standard Metrics, San Francisco, CA',
    description: 'Explored AI business solutions, created an autonomous ChatGPT parsing tool for unstructured financial documents, and client data analytics',
  },
  {
    id: 3,
    type: 'work',
    date: 'Jun 2022 - Sep 2022',
    title: 'Data Operations Intern',
    subtitle: 'PitchBook Data, Seattle, WA',
    description: 'Streamlined data workflows with automation tools - one of my intern projects I automated saved ~40 monthly hours',
  },
  {
    id: 4,
    type: 'work',
    date: 'Feb 2021 - Jun 2022',
    title: 'Financial Planning Intern',
    subtitle: 'KSH Advisors, Bellevue, WA',
    description: 'Learned about the financial planning industry while enhancing client engagement through content creation and social media',
  },
  {
    id: 5,
    type: 'education',
    date: '2020 - 2024',
    title: 'Bachelor of Arts in Business Administration',
    subtitle: 'University of Washington, Seattle, WA',
    description: 'Finance major, Computational Finance minor. Cum Laude (top 10%) - 3.79 GPA',
  },
  {
    id: 6,
    type: 'education',
    date: '2016 - 2020',
    title: 'High School Diploma + IB Certificate',
    subtitle: 'Skyline High School, Sammamish, WA',
    description: 'International Baccalaureate Career Program in Business & Math. 3.98/4.00 GPA',
  },
];

const DesktopTimelineItem: React.FC<{ 
  item: TimelineItem; 
  index: number;
}> = ({ item, index }) => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="flex items-start mb-8"
    >
      <div className="w-36 flex-shrink-0 text-right pr-6">
        <span className="text-sm text-gray-600 font-serif whitespace-nowrap">{item.date}</span>
      </div>
      <div className="flex-grow bg-cream border-2 border-gray-300 p-4 rounded-lg shadow-sm">
        <div className="flex items-center mb-2">
          <h3 className="text-lg font-serif text-gray-800 mr-2">{item.title}</h3>
          {item.type === 'work' ? <FaBriefcase className="text-gray-600" /> : <FaGraduationCap className="text-gray-600" />}
        </div>
        <p className="text-gray-600 italic mb-2 text-sm font-serif">{item.subtitle}</p>
        <p className="text-gray-700 text-sm">{item.description}</p>
      </div>
    </motion.div>
  );
};

const MobileTimelineItem: React.FC<{ item: TimelineItem }> = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-4 bg-cream border-2 border-gray-300 rounded-lg shadow-sm overflow-hidden">
      <div 
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center">
          {item.type === 'work' ? (
            <FaBriefcase className="text-gray-600 mr-3" />
          ) : (
            <FaGraduationCap className="text-gray-600 mr-3" />
          )}
          <div>
            <h3 className="font-serif text-lg text-gray-800">{item.title}</h3>
            <p className="text-sm text-gray-600">{item.date}</p>
          </div>
        </div>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <FaChevronDown className="text-gray-600" />
        </motion.div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="px-4 pb-4"
          >
            <p className="text-gray-600 italic mb-2">{item.subtitle}</p>
            <p className="text-gray-700 text-sm">{item.description}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ToggleButton: React.FC<{ 
  active: 'work' | 'education'; 
  onClick: () => void; 
}> = ({ active, onClick }) => {
  const springConfig = { type: "spring", stiffness: 700, damping: 30 };
  
  return (
    <div className="relative w-64 h-10 bg-cream border border-gray-300 rounded-full p-1 cursor-pointer" onClick={onClick}>
      <motion.div
        className="absolute top-0.5 left-0.5 w-1/2 h-8 bg-gray-200 rounded-full"
        initial={false}
        animate={{ x: active === 'work' ? 0 : "100%" }}
        transition={springConfig}
      />
      <div className="relative w-full h-full flex items-center justify-around">
        <motion.div
          className="flex items-center justify-center w-1/2 h-full z-10"
          animate={{ color: active === 'work' ? "#1a1a1a" : "#4a5568" }}
        >
          <FaBriefcase className="mr-2" />
          <span className="font-serif text-sm">Work</span>
        </motion.div>
        <motion.div
          className="flex items-center justify-center w-1/2 h-full z-10"
          animate={{ color: active === 'education' ? "#1a1a1a" : "#4a5568" }}
        >
          <FaGraduationCap className="mr-2" />
          <span className="font-serif text-sm">Education</span>
        </motion.div>
      </div>
    </div>
  );
};

const ResponsiveExperienceTimeline: React.FC = () => {
  const [filter, setFilter] = useState<'work' | 'education'>('work');
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [filter]);

  const filteredData = timelineData.filter(item => item.type === filter);

  return (
    <section id="background" className="py-12 bg-cream overflow-hidden">
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="font-serif text-3xl text-gray-800 mb-4">
          Background
          <div className="w-full h-0.5 bg-gray-300 mt-2"></div>
        </h2>
        <div className="flex justify-center mb-8">
          <ToggleButton 
            active={filter} 
            onClick={() => setFilter(filter === 'work' ? 'education' : 'work')}
          />
        </div>
        <div className="relative w-full" ref={containerRef}>
          <AnimatePresence mode="wait">
            <motion.div
              key={filter}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {isMobile ? (
                filteredData.map(item => (
                  <MobileTimelineItem key={item.id} item={item} />
                ))
              ) : (
                <div className="relative w-full max-w-3xl mx-auto">
                  {filteredData.map((item, index) => (
                    <DesktopTimelineItem 
                      key={item.id} 
                      item={item} 
                      index={index}
                    />
                  ))}
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default ResponsiveExperienceTimeline;