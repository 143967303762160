import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

const HeaderHero: React.FC = () => {
  const [age, setAge] = useState(0);
  const [llmHours, setLlmHours] = useState(1023);
  const [currentDate, setCurrentDate] = useState('');
  const controls = useAnimation();
  const rocketRef = useRef<HTMLDivElement>(null);
  const [rocketPosition, setRocketPosition] = useState({ x: -50, y: 0 });
  const [rocketDirection, setRocketDirection] = useState(1);
  const [typedText, setTypedText] = useState('');

  const fullText = 'A finance and math graduate from Univ. of Washington who loves learning, building, automating, and coding with GenAI. He even built this website in a few hours using Claude 3.5!';

  useEffect(() => {
    const birthday = new Date('2002-02-28');
    const updateAge = () => {
      const ageInMilliseconds = Date.now() - birthday.getTime();
      const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      setAge(ageInYears);
    };
    updateAge();
    const ageInterval = setInterval(updateAge, 50);

    const startDate = new Date('2024-07-22');
    const updateLlmHours = () => {
      const days = Math.floor((Date.now() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      setLlmHours(1023 + days * 4);
    };
    updateLlmHours();
    const llmInterval = setInterval(updateLlmHours, 3600000);

    const updateDate = () => {
      const now = new Date();
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(now).toUpperCase();
      setCurrentDate(formattedDate);
    };
    updateDate();
    const dateInterval = setInterval(updateDate, 1000 * 60); // Update every minute
    

    animationSequence();
    animateRocket();
    typeText();

    return () => {
      clearInterval(ageInterval);
      clearInterval(llmInterval);
    };
  }, []);

  const typeText = () => {
    let i = 0;
    const typing = setInterval(() => {
      if (i < fullText.length) {
        setTypedText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typing);
      }
    }, 20);
  };

  const animationSequence = async () => {
    await controls.start("visible");
  };

  const animateRocket = () => {
    let x = -50;
    let direction = 1;
    let y = 0;
    let angle = 0;

    const animate = () => {
      x += 1 * direction;
      angle += 0.05;
      y = Math.sin(angle) * 10;

      if (x > window.innerWidth) {
        direction = -1;
      } else if (x < -50) {
        direction = 1;
      }

      setRocketPosition({ x, y });
      setRocketDirection(direction);
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  };

  const scrollToSection = (e: React.MouseEvent, sectionId: string) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -60;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1, 
      transition: { 
        duration: 0.6, 
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { 
        type: "spring", 
        stiffness: 100, 
        damping: 10,
        duration: 1
      }
    }
  };

  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate={controls}
      className="header-hero bg-cream p-4 sm:p-8 pb-0 relative overflow-hidden"
      id="about"
    >
      <motion.div 
        ref={rocketRef} 
        className="rocket absolute z-10 text-2xl"
        style={{ 
          x: rocketPosition.x, 
          y: rocketPosition.y,
          rotate: Math.sin(rocketPosition.x * 0.01) * 10,
          scaleX: rocketDirection
        }}
      >
        🚀
      </motion.div>

      <div className="max-w-5xl mx-auto relative">
        <header className="text-center mb-8 sm:mb-16">
          <motion.h1 
            variants={titleVariants}
            className="font-serif text-4xl sm:text-6xl text-gray-800 mb-4 sm:mb-6 tracking-tight relative inline-block"
          >
            Ethan Kinnan
            <motion.span 
              className="absolute -bottom-2 left-0 w-full h-1 bg-gray-800"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            />
          </motion.h1>
          
          <div className="flex flex-wrap justify-center items-center space-x-2 sm:space-x-6 text-xs sm:text-sm text-gray-600">
        <span className="mb-2 sm:mb-0">{currentDate}</span>
        <span className="mb-2 sm:mb-0">Age: {age.toFixed(4)}</span>
        <span className="mb-2 sm:mb-0">LLM Hours: {Math.round(llmHours)}</span>
      </div>
        </header>

        <nav className="flex flex-wrap justify-center space-x-4 sm:space-x-10 text-xs sm:text-sm font-medium uppercase mb-8 sm:mb-16">
          {[
            { name: 'About', id: 'about' },
            { name: 'Projects', id: 'projects' },
            { name: 'Experience', id: 'experience' },
            { name: 'Interests', id: 'excited-for' },
            { name: 'Contact', id: 'contact' }
          ].map((item) => (
            <motion.a 
              key={item.name} 
              href={`#${item.id}`} 
              onClick={(e) => scrollToSection(e, item.id)}
              className="nav-item text-gray-700 hover:text-gray-900 mb-2 sm:mb-0"
              whileHover={{ scale: 1.1, color: "#0d9488" }}
              whileTap={{ scale: 0.95 }}
            >
              {item.name}
            </motion.a>
          ))}
        </nav>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 sm:gap-12">
          <div className="space-y-6 col-span-1 md:col-span-2">
            <h2 className="font-serif text-2xl sm:text-3xl text-gray-800 border-b pb-2">
              Who is Ethan?
            </h2>
            <p className="text-gray-700 leading-relaxed text-base sm:text-lg">
              {typedText}
            </p>
            <div className="relative">
              <div className="border-2 border-gray-300 rounded-lg p-4 sm:p-5 relative bg-cream shadow-sm">
                <span className="absolute -top-3 left-4 bg-cream px-2 text-xs sm:text-sm font-medium text-gray-600">
                  Currently
                </span>
                <p className="text-gray-700 text-base sm:text-lg">
                Using and learning more about GenAI every day while working AI Business Operations for{' '}
                <a 
                  href="https://www.alliekmiller.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#0d9488] hover:text-[#0b6f63] underline transition-colors duration-300"
                >
                  Allie K. Miller
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg shadow-lg w-2/3 mx-auto md:w-full">
            <img
              src={`${process.env.PUBLIC_URL}/ethan.jpg`}
              alt="Ethan Kinnan"
              className="w-full h-auto object-cover"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 sm:p-4">
              <p className="text-xs sm:text-sm font-medium">
                Ethan driving a boat 🚤
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeaderHero;