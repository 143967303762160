import React from 'react';
import { motion } from 'framer-motion';

interface Interest {
  title: string;
  image: string;
  description: string;
}

const interests: Interest[] = [
  {
    title: "Golf",
    image: "golf.jpg",
    description: "Only sport I don't get embarrassed in"
  },
  {
    title: "Video Games",
    image: "xbox.jpg",
    description: "Make millions of coins in ultimate team game modes"
  },
  {
    title: "Board Games",
    image: "games.jpg",
    description: "Strategy is the best"
  }
];

const Interests: React.FC = () => {
  return (
    <section className="py-12 bg-cream">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="font-serif text-3xl text-gray-800 mb-8">
          Interests
          <div className="w-full h-0.5 bg-gray-300 mt-2"></div>
        </h2>
        <div className="flex md:grid md:grid-cols-3 gap-6 overflow-x-auto pb-4 md:overflow-x-visible md:pb-0 snap-x snap-mandatory md:snap-none">
          {interests.map((interest, index) => (
            <InterestCard key={index} interest={interest} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const InterestCard: React.FC<{ interest: Interest; index: number }> = ({ interest, index }) => {
  return (
    <motion.div
      className="bg-cream border-2 border-gray-300 rounded-lg overflow-hidden shadow-sm flex-shrink-0 w-[calc(100%-2rem)] md:w-auto snap-center"
      initial={{ opacity: 1, x: index === 0 ? 0 : 20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="relative h-40 md:h-48">
        <img 
          src={`${process.env.PUBLIC_URL}/${interest.image}`} 
          alt={interest.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <h3 className="text-cream font-serif text-2xl">{interest.title}</h3>
        </div>
      </div>
      <div className="p-4">
        <p className="text-gray-700 text-sm">{interest.description}</p>
      </div>
    </motion.div>
  );
};

export default Interests;