import React from 'react';
import HeaderHero from './components/HeaderHero';
import ProjectShowcase from './components/ProjectShowcase';
import ResponsiveExperienceTimeline from './components/ExperienceTimeline';
import PersonalInterests from './components/PersonalInterests';
import ContactSection from './components/CalltoAction';
import ExcitedFor from './components/ExcitedFor'
import { Analytics } from "@vercel/analytics/react"

const App: React.FC = () => {
  return (
    <div className="bg-cream text-charcoal">
      <HeaderHero />
      <main>
        <ProjectShowcase />
        <ResponsiveExperienceTimeline />
        <ExcitedFor />
        <PersonalInterests />
        <ContactSection />
      </main>
      <Analytics />
    </div>
  );
};

export default App;