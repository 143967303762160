import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaTwitter, FaEnvelope, FaCheck } from 'react-icons/fa';

const ContactSection: React.FC = () => {
  const [copied, setCopied] = useState(false);

  const contactItems = [
    { icon: FaEnvelope, text: 'ethankinnan@gmail.com', action: handleEmailClick },
    { icon: FaLinkedin, text: 'LinkedIn', link: 'https://www.linkedin.com/in/ethan-kinnan-59ba471bb/' },
    { icon: FaTwitter, text: 'X (Twitter)', link: 'https://x.com/ethan_kinnan' },
  ];

  function handleEmailClick() {
    navigator.clipboard.writeText('ethankinnan@gmail.com');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <section id="contact" className="py-6 bg-cream">
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="font-serif text-3xl text-gray-800 mb-4">
          Get in Touch
          <div className="w-full h-0.5 bg-gray-300 mt-2"></div>
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-6 mt-8">
          {contactItems.map((item, index) => (
            <motion.a
              key={index}
              href={item.link}
              onClick={item.action}
              target={item.link ? "_blank" : undefined}
              rel={item.link ? "noopener noreferrer" : undefined}
              className="flex items-center space-x-3 bg-cream border-2 border-gray-300 rounded-full px-6 py-3 text-gray-800 hover:bg-gray-100 transition-colors duration-300 cursor-pointer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {item.icon === FaEnvelope && copied ? (
                <FaCheck className="text-xl text-green-500" />
              ) : (
                <item.icon className="text-xl" />
              )}
              <span className="font-serif">{item.text}</span>
            </motion.a>
          ))}
        </div>
        <motion.div 
          className="mt-8 text-center text-gray-600"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <p className="font-serif">I'm always excited to discuss new opportunities, collaborate on projects, or just chat about using language models. Whether you have a specific project in mind or simply want to connect, don't hesitate to reach out!</p>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactSection;